import React, { useState, useEffect, useCallback ,useMemo} from "react";
import Close from "../../../../assets/images/close-black.svg";
import FilterImg from "../../../../assets/images/filter-black.svg";
import Select from "react-select";
// import DateRangePickerComponent from "./DateRangePickerComponent";

import "react-dropdown-tree-select/dist/styles.css"; // Import the CSS
import DropdownTreeSelect from "react-dropdown-tree-select";

var options = [];
var stageOption = [];
var folderOption = [];
var treeSelectOption = [];

const Filter = ({
    filterFlag,
    filterFunction,
    getFilterData,
    filterDropdownOption,
    filterApply,
    //oldSelected,
    oldSelectedStage,
    oldSelectedFolder,
    setAllOption,
    oldSelectedMultiOptions,
}) => {
    // const [selectedOption, setSelectedOption] = useState(null);
    // const [option, setOption] = useState([]);
    const [stageOptions, setStageOptions] = useState([]);
    const [folderOptions, setFolderOptions] = useState([]);
    const [selectedStageOption, setSelectedStageOption] = useState(null);
    const [selectedFolderOption, setSelectedFolderOption] = useState(null);

    //const [selectedMultiOption, setSelectedMultiOption] = useState([]);

    const [multiOptions, setMultiOptions] = useState([]);

    // const handleChange = selected => {
    //     setSelectedOption(selected);
    //     getFilterData(selected, "dropdownValue");
    // };

    const handleStageChange = selected => {
        setSelectedStageOption(selected);
        getFilterData(selected, "stageValue");
    };

    const handleFolderChange = selected => {
        setSelectedFolderOption(selected);
        getFilterData(selected, "folderValue");
    };

    // Handle selection changes
    const onChange = useCallback((currentNode, selectedNodes) => {
        let arrayValue = [];
        console.log("currentNode", currentNode);
        if (selectedNodes.length > 0) {
            selectedNodes.map(item => {
                arrayValue.includes(item.value)
                    ? ""
                    : arrayValue.push({
                          label: item.label,
                          value: item.value,
                          parent_id: item.parent_id,
                      });

                item?.nextChild?.length
                    ? childOptionDataPrepare(item?.nextChild, arrayValue)
                    : null;
            });
            //setSelectedMultiOption(arrayValue);
            getFilterData(arrayValue, "multiSelectDropdownValue");
        } else {
            //setSelectedMultiOption([]);
            getFilterData([], "multiSelectDropdownValue");
        }
    }, []);

    const childOptionDataPrepare = (selectedChildNodes, arrayValue, flag) => {
        if (selectedChildNodes.length > 0) {
            selectedChildNodes.map(itemChild => {
                itemChild?.nextChild?.length
                    ? childOptionDataPrepare(
                          itemChild?.nextChild,
                          arrayValue,
                          flag,
                      )
                    : null;
                arrayValue.includes(itemChild.value)
                    ? ""
                    : arrayValue.push({
                          label: itemChild.label,
                          value: itemChild.value,
                          parent_id: itemChild.parent_id,
                      });
            });
        }
    };

    useEffect(() => {
        options = [];
        stageOption = [];
        treeSelectOption = [];
        //setSelectedOption(oldSelected);
        setSelectedStageOption(oldSelectedStage);
        setSelectedFolderOption(oldSelectedFolder);
        //setSelectedMultiOption(oldSelectedMultiOptions);

        let obj = {
            label: filterDropdownOption.name,
            value: filterDropdownOption.id,
        };

        if (obj?.value) {
            options.push(obj);
        }

        if (filterDropdownOption?.children) {
            treeSelectOption =
                filterDropdownOption?.children.map(transformData);
            setMultiOptions(treeSelectOption);
        } else {
            if (filterDropdownOption?._children) {
                treeSelectOption =
                    filterDropdownOption?._children.map(transformData);
                setMultiOptions(treeSelectOption);
            }
        }

        findOption(filterDropdownOption, 1);
        options.reduce((accumulator, currentObj) => {
            const existingObj = accumulator.find(
                obj => obj.id === currentObj.id,
            );
            if (!existingObj) {
                accumulator.push(currentObj);
            }
            return accumulator;
        }, []);

        let stageValue = stageOption.reduce((acc, curr) => {
            const eObj = acc.find(obj => {
                return obj.value == curr.value;
            });
            if (!eObj) {
                acc.push(curr);
            }
            return acc;
        }, []);

        let folderValue = folderOption.reduce((a, c) => {
            const existObj = a.find(obj => obj.value == c.value);
            if (!existObj) {
                a.push(c);
            }
            return a;
        }, []);

        if (options.length) {
            setAllOption(options);
            //setOption(options);
        }

        if (stageOption.length) {
            setStageOptions(stageValue);
        }

        if (folderOption.length) {
            setFolderOptions(folderValue);
        }
    }, [filterFlag, filterDropdownOption]);

    function transformData(node) {
        let valuesArray = [];
        let flagCheck = false;
        let nextChild = node?.children
            ? node.children.map(transformData)
            : node?._children
            ? node._children.map(transformData)
                : [];
        if (oldSelectedMultiOptions?.length) {
            valuesArray = oldSelectedMultiOptions?.map(item => item?.value);
            valuesArray = Array.from(new Set(valuesArray.flat()));
            flagCheck = valuesArray.includes(node?.id);
        }
        const transformedNode = {
            label: node?.name,
            value: node?.id,
            children: nextChild,
            nextChild: nextChild,
            parent_id: searchRecursive([filterDropdownOption], node.id),
            checked: flagCheck,
        };
        return transformedNode;
    }

    function searchRecursive(items, id) {
        const allIds = [];

        items.forEach(async item => {
            if (item.id === id) {
                allIds.push(item.id);
            } else if (item.children) {
                const ids = await searchRecursive(item.children, id);

                if (ids.length) allIds.push(item.id);

                ids.forEach(id => allIds.push(id));
            } else if (item._children) {
                const ids = await searchRecursive(item._children, id);

                if (ids.length) allIds.push(item.id);

                ids.forEach(id => allIds.push(id));
            }
        });

        return allIds;
    }

    function findOption(node, level) {
        if (node?.children) {
            node.children.forEach(child => {
                let obj = {
                    label: child.name,
                    value: child.id,
                    stageStatus:
                        level > 1
                            ? child?.stageState?.name
                                ? child?.stageState?.name
                                : "default"
                            : "_",
                    parent_id: searchRecursive(
                        [filterDropdownOption],
                        child.id,
                    ),
                };
                options.push(obj);

                if (level === 1) {
                    let stageObj = {
                        label: child?.stageState?.name
                            ? child?.stageState?.name
                            : "default",
                        value: child?.stageState?.name
                            ? child?.stageState?.name
                            : "default",
                        stageStatus:
                            level > 1
                                ? child?.stageState?.name
                                    ? child?.stageState?.name
                                    : "default"
                                : "_",
                        parent_id: searchRecursive(
                            [filterDropdownOption],
                            child.id,
                        ),
                    };
                    stageOption.push(stageObj);
                }

                if (level > 1) {
                    let folderObj = {
                        label: child?.stageState?.name
                            ? child?.stageState?.name
                            : "default",
                        value: child?.stageState?.name
                            ? child?.stageState?.name
                            : "default",
                        stageStatus:
                            level > 1
                                ? child?.stageState?.name
                                    ? child?.stageState?.name
                                    : "default"
                                : "_",
                        parent_id: searchRecursive(
                            [filterDropdownOption],
                            child.id,
                        ),
                    };
                    folderOption.push(folderObj);
                }

                findOption(child, level + 1);
            });
        } else {
            if (node?._children) {
                node?._children.forEach(child => {
                    let obj = {
                        label: child.name,
                        value: child.id,
                        stageStatus:
                            level > 1
                                ? child?.stageState?.name
                                    ? child?.stageState?.name
                                    : "default"
                                : "_",
                        parent_id: searchRecursive(
                            [filterDropdownOption],
                            child.id,
                        ),
                    };
                    options.push(obj);

                    if (level === 1) {
                        let stageObj = {
                            label: child?.stageState?.name
                                ? child?.stageState?.name
                                : "default",
                            value: child?.stageState?.name
                                ? child?.stageState?.name
                                : "default",
                            stageStatus:
                                level > 1
                                    ? child?.stageState?.name
                                        ? child?.stageState?.name
                                        : "default"
                                    : "_",
                            parent_id: searchRecursive(
                                [filterDropdownOption],
                                child.id,
                            ),
                        };
                        stageOption.push(stageObj);
                    }

                    if (level > 1) {
                        let folderObj = {
                            label: child?.stageState?.name
                                ? child?.stageState?.name
                                : "default",
                            value: child?.stageState?.name
                                ? child?.stageState?.name
                                : "default",
                            stageStatus:
                                level > 1
                                    ? child?.stageState?.name
                                        ? child?.stageState?.name
                                        : "default"
                                    : "_",
                            parent_id: searchRecursive(
                                [filterDropdownOption],
                                child.id,
                            ),
                        };
                        folderOption.push(folderObj);
                    }

                    findOption(child, level + 1);
                });
            }
        }
    }

    const handleClear = () => {
        //setSelectedOption(null);
        filterFunction(true, "clear");
    };

    const multiSelect = useMemo(() => {
        return (
            <DropdownTreeSelect
                data={multiOptions}
                expanded={true}
                texts={{
                    placeholder: "Asama/ Klasor",
                }}
                onChange={onChange}
                className="mdl-demo"
                keepTreeOnSearch={true}
                keepChildrenOnSearch={true}
                // mode="hierarchical"
                hierarchical={true}
                //showDropdown="initial"
                //selected={treeDropdownOption}
            />
        );
    }, [multiOptions]);

    return (
        <React.Fragment>
            {filterFlag ? (
                <div className="filter_section">
                    <div className="card">
                        <div className="card-header">
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className="mb-0">
                                    <img src={FilterImg} /> <span>Filters</span>
                                </h5>
                                <div>
                                    <a
                                        href="javascript:;"
                                        onClick={() => {
                                            handleClear();
                                        }}>
                                        {" "}
                                        <span>Clear Filters</span>{" "}
                                    </a>
                                    <img
                                        src={Close}
                                        onClick={() => {
                                            filterFunction(false),
                                                //setSelectedOption(oldSelected),
                                                setSelectedStageOption(
                                                    oldSelectedStage,
                                                );
                                        }}
                                        alt="check"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form>
                                {/* <div className="row">
                                    <div className="col-12">
                                        <div className="mb-3 dropdownSelectMerchantDiv">
                                            <label className="form-label">
                                                Asama/ Klasor
                                            </label>
                                            <DropdownTreeSelect
                                                data={multiOptions}
                                                expanded={true}
                                                //onChange={handleChange}
                                                texts={{
                                                    placeholder:
                                                        "Asama/ Klasor",
                                                }}
                                                onChange={onChange}
                                                className="mdl-demo"
                                                keepTreeOnSearch={true}
                                                keepChildrenOnSearch={true}
                                                //mode="hierarchical"
                                                hierarchical={true}
                                                showDropdown="initial"
                                                //selected={treeDropdownOption}
                                            />
                                        </div>
                                    </div>
                                </div> */}

                                <div className="row">
                                    <div className="col-12">
                                        <div className="mb-3 dropdownSelectMerchantDiv">
                                            <label className="form-label">
                                                Asama/ Klasor
                                            </label>

                                            {multiSelect}
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="row">
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Asama/ Klasor
                                            </label>
                                            <Select
                                                className=""
                                                value={selectedOption}
                                                onChange={handleChange}
                                                options={option}
                                                isSearchable // Enable search feature
                                                isClearable // Show clear 'x' button to reset selection
                                                isMulti
                                            />
                                        </div>
                                    </div>
                                </div> */}

                                <div className="row">
                                    <div className="col-6">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Stages State
                                            </label>
                                            <Select
                                                className=""
                                                value={selectedStageOption}
                                                onChange={handleStageChange}
                                                options={stageOptions}
                                                isSearchable // Enable search feature
                                                isClearable // Show clear 'x' button to reset selection
                                                isMulti
                                            />
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Folder State
                                            </label>
                                            <Select
                                                className=""
                                                value={selectedFolderOption}
                                                onChange={handleFolderChange}
                                                options={folderOptions}
                                                isSearchable // Enable search feature
                                                isClearable // Show clear 'x' button to reset selection
                                                isMulti
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="row">
                                    <div className="col-6">
                                        <DropdownTreeSelect
                                            data={multiJson?.data}
                                            onChange={onChange}
                                            texts={{
                                                placeholder: "Select items",
                                            }}
                                            mode="multiSelect" // Set the mode to multiSelect
                                            showDropdown="always"
                                            className="my-dtree-class"
                                            //selected={multiOptions}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <CustomDropdown />
                                    </div>
                                </div> */}
                            </form>
                        </div>
                        <div className="card-footer">
                            <div className="text-right">
                                <a
                                    href="javascript:;"
                                    className="btn btn-sm btn-light mr-2"
                                    onClick={() => {
                                        filterFunction(false),
                                            //setSelectedOption(oldSelected),
                                            setSelectedStageOption(
                                                oldSelectedStage,
                                            ),
                                            setSelectedFolderOption(
                                                oldSelectedFolder,
                                            );
                                    }}>
                                    Cancel
                                </a>
                                <a
                                    href="javascript:;"
                                    className="btn btn-sm btn-black"
                                    onClick={() => {
                                        filterApply(true);
                                    }}>
                                    Apply
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
};
export default Filter;