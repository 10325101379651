import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import * as d3 from "d3";
import WordFile from "../../../../assets/images/word.png";
import RightArrow from "../../../../assets/images/right-arrow.png";
import userProfile from "../../../../assets/images/user-profile.jpeg";
// import { addDays, format } from "date-fns";
import Highlighter from "react-highlight-words";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ReactDOMServer from "react-dom/server"; // Add this line
import IconPopup from "./IconPopup";

//Find the total height and width of screen
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
}
// Initialize a variables to keep track of the currently opened node
var openedNode = null;
var openedNodeValue = {};
var findDepthValue = 1;

var levelData = {};
levelData.children = [];
let clickEventFind="mouse";

const TreeChart = ({
    data,
    graphView,
    flag,
    selectedLevel,
    onTranslateUpdate,
    depthType,
    depthCheck,
    maxDepth,
    onDepthChange,
    depthProp,
    zoomValue,
    filterApplyFlag,
    contentSearch,
    selected,
    filterClear,
    isLoading,
    svgHeight,
    svgWidth,
    imageFlag,
    selectedStage,
    selectedFolder,
    allOption,
    selectedMultiOptions,
}) => {
    //filterDate,

    const windowDimensions = getWindowDimensions();

    const [margin, setMargin] = useState({
        top: 20,
        right: 120,
        bottom: 20,
        left: 120,
    });
    const [treeHeight, setTreeHeight] = useState(40);
    const [treeWidth, setTreeWidth] = useState(240);
    const [svgTranslateChange, setSvgTranslateChange] = useState(
        graphView === "horizontal"
            ? [140, windowDimensions?.height / 2]
            : [windowDimensions?.width / 2, 180],
    );
    const [chartWidth, setchartWidth, chartWidthRef] = useState(0);
    const [chartHeight, setchartHeight, chartHeightRef] = useState(0);
    const [
        openStageDetailFlag,
        setOpenStageDetailFlag,
        openStageDetailFlagRef,
    ] = useState(false);
    const [showIconPopup, setShowIconPopup] = useState(false);
    const [iconNode, setIconNode, iconNodeRef] = useState({});
    const [scaleTransform, setScaleTransform] = useState([
        { name: "zoom-1", percent: 50, scale: 0.3, x: "-6%", y: "-147%" },
        { name: "zoom-2", percent: 60, scale: 0.45, x: "-6%", y: "-77%" },
        { name: "zoom-3", percent: 70, scale: 0.6, x: "-6%", y: "-43%" },
        { name: "zoom-4", percent: 80, scale: 0.75, x: "-6%", y: "-20%" },
        { name: "zoom-5", percent: 90, scale: 0.9, x: "-6%", y: "-5%" },
        { name: "zoom0", percent: 100, scale: 1, x: "0%", y: "0%" },
        { name: "zoom1", percent: 110, scale: 1.4, x: "-6%", y: "20%" },
        { name: "zoom2", percent: 120, scale: 1.8, x: "-6%", y: "30%" },
        { name: "zoom3", percent: 130, scale: 2.2, x: "-6%", y: "35%" },
        { name: "zoom4", percent: 140, scale: 2.6, x: "-6%", y: "40%" },
        { name: "zoom5", percent: 150, scale: 3, x: "-6%", y: "45%" },
    ]);
    const [scaleHorizontalTransform, setScaleHorizontalTransform] = useState([
        { name: "zoom-1", percent: 50, scale: 0.3, x: "-118%", y: "-15%" },
        { name: "zoom-2", percent: 60, scale: 0.45, x: "-63%", y: "-15%" },
        { name: "zoom-3", percent: 70, scale: 0.6, x: "-33%", y: "-15%" },
        { name: "zoom-4", percent: 80, scale: 0.75, x: "-17%", y: "-15%" },
        { name: "zoom-5", percent: 90, scale: 0.9, x: "-5%", y: "-15%" },
        { name: "zoom0", percent: 100, scale: 1, x: "0%", y: "-5%" },
        { name: "zoom1", percent: 110, scale: 1.4, x: "15%", y: "-5%" },
        { name: "zoom2", percent: 120, scale: 1.8, x: "25%", y: "-5%" },
        { name: "zoom3", percent: 130, scale: 2.2, x: "30%", y: "-5%" },
        { name: "zoom4", percent: 140, scale: 2.6, x: "35%", y: "-5%" },
        { name: "zoom5", percent: 150, scale: 3, x: "40%", y: "-5%" },
    ]);

    const graphRef = useRef(null);

    let viewFlag = false;

    useEffect(() => {
        if (!viewFlag) {
            setShowIconPopup(false);
            setIconNode({});
            setMargin({ top: 20, right: 120, bottom: 20, left: 120 });
            onTranslateUpdate(zoomValue, "zoom");
            createTreeChart("orientation");
            viewFlag = true;
        }
        setScaleTransform([
            { name: "zoom-1", percent: 50, scale: 0.3, x: "-6%", y: "-147%" },
            { name: "zoom-2", percent: 60, scale: 0.45, x: "-6%", y: "-77%" },
            { name: "zoom-3", percent: 70, scale: 0.6, x: "-6%", y: "-43%" },
            { name: "zoom-4", percent: 80, scale: 0.75, x: "-6%", y: "-20%" },
            { name: "zoom-5", percent: 90, scale: 0.9, x: "-6%", y: "-5%" },
            { name: "zoom0", percent: 100, scale: 1 },
            { name: "zoom1", percent: 110, scale: 1.4, x: "-6%", y: "20%" },
            { name: "zoom2", percent: 120, scale: 1.8, x: "-6%", y: "30%" },
            { name: "zoom3", percent: 130, scale: 2.2, x: "-6%", y: "35%" },
            { name: "zoom4", percent: 140, scale: 2.6, x: "-6%", y: "40%" },
            { name: "zoom5", percent: 150, scale: 3, x: "-6%", y: "45%" },
        ]);
        setScaleHorizontalTransform([
            { name: "zoom-1", percent: 50, scale: 0.3, x: "-118%", y: "-15%" },
            { name: "zoom-2", percent: 60, scale: 0.45, x: "-63%", y: "-15%" },
            { name: "zoom-3", percent: 70, scale: 0.6, x: "-33%", y: "-15%" },
            { name: "zoom-4", percent: 80, scale: 0.75, x: "-17%", y: "-15%" },
            { name: "zoom-5", percent: 90, scale: 0.9, x: "-5%", y: "-15%" },
            { name: "zoom0", percent: 100, scale: 1, x: "0%", y: "-5%" },
            { name: "zoom1", percent: 110, scale: 1.4, x: "15%", y: "-5%" },
            { name: "zoom2", percent: 120, scale: 1.8, x: "25%", y: "-5%" },
            { name: "zoom3", percent: 130, scale: 2.2, x: "30%", y: "-5%" },
            { name: "zoom4", percent: 140, scale: 2.6, x: "35%", y: "-5%" },
            { name: "zoom5", percent: 150, scale: 3, x: "40%", y: "-5%" },
        ]);
    }, [graphView]);

    useEffect(() => {
        onTranslateUpdate(svgTranslateChange, "svgTranslate");
    }, [svgTranslateChange]);

    useEffect(() => {
        if (isLoading) {
            setShowIconPopup(false);
            setIconNode({});
            if (openedNode) {
                removeOrHideForeignObject(openedNode, "detail-popup");
                openedNode = null;
                openedNodeValue = {};
            }
        }
    }, [isLoading]);

    useEffect(() => {
        if (depthProp !== null) {
            setShowIconPopup(false);
            setIconNode({});
            levelDataFilter();
            //onTranslateUpdate(5,"zoom");
        }
    }, [depthCheck]);

    //Function used for filter data for different level data show with check depth value
    function levelDataFilter() {
        if (depthType === "down") {
            if (depthProp > 0) {
                if (depthProp - 1 === 0) {
                    data.nodeShow = selectedLevel?.level ? false : true;
                    if (data?.children?.length) {
                        data._children = data?.children;
                        data.children = null;
                    }
                } else {
                    data.nodeShow = selectedLevel?.level ? false : true;
                    if (data?._children?.length) {
                        data.children = data?._children;
                        data._children = null;
                    }
                    let endLevel = depthProp - 1;
                    data?.children?.length
                        ? data?.children.forEach(d => showLevel(d, 1, endLevel))
                        : [];
                }
            } else {
                data.nodeShow = selectedLevel?.level ? false : true;
                if (data?.children?.length) {
                    data._children = data?.children;
                    data.children = null;
                }
            }
        } else if (depthType === "up") {
            data.nodeShow = selectedLevel?.level ? false : true;
            if (data?._children?.length) {
                data.children = data?._children;
                data._children = null;
            }
            let endLevel =
                depthProp < maxDepth
                    ? depthProp +
                      (selectedLevel?.level ? selectedLevel?.level : 1)
                    : maxDepth;
            data?.children?.length
                ? data?.children.forEach(d => showLevel(d, 1, endLevel))
                : [];
        }

        createTreeChart("levelManage");
    }

    //Function used for  data prepare for show level
    function showLevel(node, level, endLevel) {
        if (endLevel > level) {
            node.nodeShow = selectedLevel?.level > level ? false : true;
            if (node?.children?.length) {
                node.nodeShow = selectedLevel?.level > level ? false : true;
                node.stageDetailFlag = false;
                node._children = null;
                node?.children.forEach(d => showLevel(d, level + 1, endLevel));
            } else {
                if (node?._children?.length) {
                    node.stageDetailFlag = false;
                    node.nodeShow = selectedLevel?.level > level ? false : true;
                    node.children = node?._children;
                    node._children = null;
                    node?.children.forEach(d =>
                        showLevel(d, level + 1, endLevel),
                    );
                }
            }
        } else if (level === endLevel) {
            node.nodeShow = true;
            if (node?.children?.length) {
                node.nodeShow = true;
                node.stageDetailFlag = false;
                node._children = node?.children;
                node?._children.forEach(d => showLevel(d, level + 1, endLevel));
                node.children = null;
            } else {
                if (node?._children?.length) {
                    node.stageDetailFlag = false;
                    node.nodeShow = true;
                    node?._children.forEach(d =>
                        showLevel(d, level + 1, endLevel),
                    );
                    node.children = null;
                }
            }
        } else if (level > endLevel) {
            node.nodeShow = false;
            if (node?.children?.length) {
                node.nodeShow = false;
                node._children = node?.children;
                node.stageDetailFlag = false;
                node.children = null;
                node?._children.forEach(d => showLevel(d, level + 1, endLevel));
            } else {
                if (node?._children?.length) {
                    node.stageDetailFlag = false;
                    node.nodeShow = false;
                    node.children = null;
                    node?._children.forEach(d =>
                        showLevel(d, level + 1, endLevel),
                    );
                }
            }
        }
    }

    useEffect(() => {
        // setShowIconPopup(false);
        // setIconNode({});
        levelData = {};
        levelData.children = [];
        if (selectedLevel?.value) {
            onTranslateUpdate(zoomValue, "zoom");
            createTreeChart("level");
        }
    }, [selectedLevel]);

    useEffect(() => {
        setShowIconPopup(false);
        setIconNode({});
        setTranslateDimensions();
    }, [zoomValue]);

    useEffect(() => {
        setShowIconPopup(false);
        setIconNode({});
        if (filterApplyFlag && filterClear !== "clear") {
            if (
                selected?.length ||
                selectedStage?.length ||
                selectedFolder?.length ||
                selectedMultiOptions?.length
            ) {
                //Its called for data prepare on apply filter
                filterApplyFunction();
            }
            createTreeChart("filter");
        }
    }, [filterApplyFlag]);

    function filterApplyFunction() {
        let valuesArray = [];
        var stageArray = [];
        var folderArray = [];
        var folderIdArray = [];
        var multiSelectArray = [];

        if (selected?.length) {
            valuesArray = selected?.map(item => item?.parent_id);
            valuesArray = Array.from(new Set(valuesArray.flat()));
        }

        if (selectedStage?.length) {
            stageArray = selectedStage?.map(item => item?.value);
            stageArray = Array.from(new Set(stageArray.flat()));
        }

        if (selectedFolder?.length) {
            folderArray = selectedFolder?.map(item => item?.value);
            folderArray = Array.from(new Set(folderArray.flat()));
        }

        if (selectedFolder?.length) {
            const filteredItems = allOption.filter(item =>
                folderArray.includes(item.stageStatus),
            );
            folderIdArray = filteredItems?.map(item => item?.parent_id);
            folderIdArray = Array.from(new Set(folderIdArray.flat()));
        }

        if (selectedMultiOptions?.length) {
            multiSelectArray = selectedMultiOptions?.map(
                item => item?.parent_id,
            );
            multiSelectArray = Array.from(new Set(multiSelectArray.flat()));
        }

        setOpenStageDetailFlag(false);
        //Function used for filter data prepare on apply filter
        filterDataPrepration(
            data,
            valuesArray,
            stageArray,
            folderArray,
            folderIdArray,
            multiSelectArray,
            0,
        );
    }

    useEffect(() => {
        setShowIconPopup(false);
        setIconNode({});
        if (filterClear === "clear") {
            //filterApplyFunction();
            createTreeChart("filter");
        }
    }, [filterClear]);

    //Recursive function for prepare filter data
    function filterDataPrepration(
        node,
        valuesArray,
        stageArray,
        folderArray,
        folderIdArray,
        multiSelectArray,
        level,
    ) {
        if (level <= 1) {
            node.nodeShow = true;
        }
        node.stagesColorFlag = false;
        let checkValue = false;
        if (
            valuesArray.length > 0 ||
            stageArray.length > 0 ||
            folderArray.length > 0 ||
            multiSelectArray.length > 0
        ) {
            const isValuePresent = valuesArray.includes(node.id);

            let isStagePresent = false;
            let isFolderPresent = false;
            let isFolderIdsPresent = folderIdArray.includes(node?.id);
            let isMultiOptionIdsPresent = multiSelectArray.includes(node?.id);

            if (level === 1) {
                isStagePresent = stageArray.includes(
                    node?.stageState?.name ? node?.stageState?.name : "default",
                );
            }

            if (level > 1) {
                isFolderPresent = folderArray.includes(
                    node?.stageState?.name ? node?.stageState?.name : "default",
                );
            }

            if (
                isValuePresent ||
                isStagePresent ||
                isFolderPresent ||
                isFolderIdsPresent ||
                isMultiOptionIdsPresent
            ) {
                node.searchFilterFlag = true;
                checkValue = true;
            } else {
                node.searchFilterFlag = false;
                checkValue = false;
            }
        } else {
            node.searchFilterFlag = true;
        }

        if (node?.children) {
            node?.children?.length
                ? node.children.forEach(child => {
                      filterDataPrepration(
                          child,
                          valuesArray,
                          stageArray,
                          folderArray,
                          folderIdArray,
                          multiSelectArray,
                          level + 1,
                      );
                  })
                : [];
        } else {
            if (node?._children) {
                if (checkValue) {
                    node.children = node?._children;
                    node._children = null;
                    node?.children?.length
                        ? node.children.forEach(child => {
                              filterDataPrepration(
                                  child,
                                  valuesArray,
                                  stageArray,
                                  folderArray,
                                  folderIdArray,
                                  multiSelectArray,
                                  level + 1,
                              );
                          })
                        : [];
                }

                node?._children?.length
                    ? node._children.forEach(child => {
                          filterDataPrepration(
                              child,
                              valuesArray,
                              stageArray,
                              folderArray,
                              folderIdArray,
                              multiSelectArray,
                              level + 1,
                          );
                      })
                    : [];
            }
        }
    }

    const randerHighlighter = text => {
        return (
            <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[contentSearch]}
                textToHighlight={text}
                activeIndex="-1"
                caseSensitive={false}
            />
        );
    };

    useEffect(() => {
        console.log(chartHeight, chartWidth); //this need to be include for use react-usestateref package, othewise es lint throw error
        if (imageFlag) {
            setchartHeight(svgHeight);
            setchartWidth(svgWidth);
        }
    }, [imageFlag]);

    const createTreeChart = type => {
        if (flag) {
            const svgClear = d3.select(graphRef.current);
            // Clear the existing graph
            svgClear.selectAll("*").remove();
        }
        if (type === "levelManage" || type === "filter") {
            const svgClear = d3.select(graphRef.current);
            // Clear the existing graph
            svgClear.selectAll("*").remove();
        }
        //var i = 0;
        var duration = 750;
        var rectW = 200;
        var rectH = 75;
        var reactTNW = 105;
        var reactTNH = 40;
        var height = 800 - margin.top - margin.bottom;

        var tree = d3.layout
            .tree()
            .nodeSize([220, 65])
            .separation(function (a, b) {
                return a.parent == b.parent ? 1 : 1.04;
            });

        const diagonal = d3.svg.diagonal().projection(function (d) {
            return graphView === "horizontal" ? [d.y, d.x] : [d.x, d.y];
        });

        let zm = d3.behavior
            .zoom()
            .scaleExtent([1, 3])
            .on("zoom", redraw)
            .on("zoomend", zoomEndFunction);

        const svg = d3
            .select(graphRef.current)
            .append("svg")
            .attr("width", "100%")
            .attr("height", "100%")
            .call(zm)
            .append("g")
            .attr(
                "transform",
                graphView === "horizontal"
                    ? "translate(" +
                          140 +
                          "," +
                          windowDimensions?.height / 2 +
                          ")"
                    : "translate(" +
                          windowDimensions?.width / 2 +
                          "," +
                          180 +
                          ")",
            );

        //necessary so that zoom knows where to zoom and unzoom from
        zm.translate(
            graphView === "horizontal"
                ? [140, windowDimensions?.height / 2]
                : [windowDimensions?.width / 2, 180],
        );
        data.x0 = 0;
        data.y0 = height / 2;

        // if(selected?.length){
        filterApplyFunction();
        //}

        if (type !== "levelManage") {
            if (type === "filter") {
                if (data?._children) {
                    data.children = data?._children;
                    data._children = null;

                    data?.children?.length
                        ? data.children.forEach(collapse)
                        : [];
                    data?.children?.length
                        ? data.children.forEach(d => collapse2(d, type))
                        : [];
                } else {
                    data?.children?.length
                        ? data.children.forEach(collapse)
                        : [];
                    data?.children?.length
                        ? data.children.forEach(d => collapse2(d, type))
                        : [];
                }
            } else {
                data?.children?.length ? data.children.forEach(collapse) : [];
                data?.children?.length
                    ? data.children.forEach(d => collapse2(d, type))
                    : [];
            }
        }

        if (type === "levelManage" || type === "filter") {
            onTranslateUpdate(zoomValue, "zoom");
            if (type === "filter") {
                update(data, true);
            } else {
                update(data);
            }
            setSvgDimensions();

            return true;
        }

        data?.children?.length ? data.children.forEach(collapse) : [];

        if (selectedLevel?.level === 0) {
            data.nodeShow = true;
        }

        if (type === "orientation" && selectedLevel?.level == undefined) {
            data.nodeShow = true;
        }

        if (type === "level") {
            if (selectedLevel?.value) {
                data.nodeShow = false;
                if (data?._children?.length) {
                    data.children = data?._children;
                    data._children = null;
                }
                data?.children?.length
                    ? data.children.forEach(d => collapseLevel(d, 1))
                    : [];

                if (selectedLevel?.level === 0) {
                    data.nodeShow = true;
                    if (data?.children?.length) {
                        data._children = data?.children;
                        data.children = null;
                    }
                } else {
                    data.nodeShow = false;
                }

                if (selectedLevel?.level === 2) {
                    data.nodeShow = false;
                    data?.children?.length
                        ? data.children.map(node => {
                              node.nodeShow = false;
                          })
                        : [];
                }
                onTranslateUpdate(zoomValue, "zoom");
            }
        }

        var nodes = tree.nodes(data).reverse();
        findDepthValue = d3.max(nodes, function (d) {
            return d.depth;
        });
        onDepthChange(findDepthValue);

        if (type === "level") {
            if (selectedLevel?.value) {
                setShowIconPopup(false);
                setIconNode({});
            }

            if (selectedLevel?.level) {
                update(levelData);
            } else {
                update(data);
            }
            if (
                selected?.length > 0 ||
                selectedStage?.length > 0 ||
                selectedFolder?.length ||
                selectedMultiOptions?.length
            ) {
                createTreeChart("filter");
            }
        } else {
            update(data);
        }

        //collaps level depend on arg type value
        function collapseLevel(d, level) {
            if (selectedLevel?.level === level) {
                d.nodeShow = true;
                d.stageDetailFlag = false;
                levelData?.children.push(d);
            }
            if (selectedLevel?.value) {
                if (selectedLevel?.level === level) {
                    if (d.children) {
                        if (d?.children?.length) {
                            d.nodeShow = true;
                            d.stageDetailFlag = false;
                            d._children = d.children;
                            d._children.forEach(d =>
                                collapseLevel(d, level + 1),
                            );
                            d.children = null;
                        }
                    }
                } else if (selectedLevel?.level > level) {
                    if (d.children) {
                        if (d?.children?.length) {
                            d.nodeShow = false;
                            d.nodeShow = false;
                            d.stageDetailFlag = false;
                            d.children.forEach(d =>
                                collapseLevel(d, level + 1),
                            );
                            d._children = null;
                        }
                    } else {
                        if (d?._children?.length) {
                            d.children = d._children;
                            d.nodeShow = false;
                            d.nodeShow = false;
                            d.stageDetailFlag = false;
                            d.children.forEach(d =>
                                collapseLevel(d, level + 1),
                            );
                            d._children = null;
                        }
                    }
                } else {
                    if (d?.children?.length) {
                        d._children = d.children;
                        d.nodeShow = false;
                        d.nodeShow = false;
                        d.stageDetailFlag = false;
                        d._children.forEach(d => collapseLevel(d, level + 1));
                        d.children = null;
                    } else {
                        if (d?._children?.length) {
                            d.nodeShow = false;
                            d.nodeShow = false;
                            d.stageDetailFlag = false;
                            d._children.forEach(d =>
                                collapseLevel(d, level + 1),
                            );
                            d.children = null;
                        }
                    }
                }
            }
            if (selectedLevel?.level) {
                levelData.name = selectedLevel?.label;
                levelData.description = selectedLevel?.label;
                levelData.id =
                    selectedLevel?.level + "-" + selectedLevel?.label;
                levelData.stageState = null;
                levelData.nodeShow = true;
                levelData.stagesColorFlag = true;
                levelData.searchFilterFlag = true;
            }
        }

        function collapse(d) {
            if (!selectedLevel?.value && !flag) {
                data.nodeShow = true;
                d.stageDetailFlag = false;
                if (d.children) {
                    d._children = d.children;
                    d.nodeShow = true;
                    d._children.forEach(collapse);
                    d.children = null;
                }
            }
        }

        function collapse2(d, type) {
            //data.nodeShow = true;
            d.stageDetailFlag = false;

            if (
                d.searchFilterFlag == true &&
                (selected?.length > 0 ||
                    selectedStage?.length > 0 ||
                    selectedFolder?.length ||
                    selectedMultiOptions.length)
            ) {
                if (d.children) {
                    //
                } else if (d._children?.length) {
                    d.children = d._children;
                    d.children.forEach(b => collapse2(b, type));
                    d._children = null;
                }
            } else {
                if (type === "filter") {
                    if (d.children?.length) {
                        d._children = d.children;
                        d._children.forEach(b => collapse2(b, type));
                        d.children = null;
                    }
                }
            }
        }

        function update(source) {
            if (graphView === "horizontal") {
                tree = d3.layout
                    .tree()
                    .nodeSize([120, 65])
                    .separation(function (a, b) {
                        return a.parent == b.parent ? 1 : 1.04;
                    });
            } else {
                tree = d3.layout
                    .tree()
                    .nodeSize([220, 65])
                    .separation(function (a, b) {
                        return a.parent == b.parent ? 1 : 1.04;
                    });
            }

            var nodes = tree
                    .nodes(selectedLevel?.level ? levelData : data)
                    .reverse(),
                links = tree.links(nodes);

            // Calculate the height and width of the tree.
            setTreeHeight(
                d3.max(nodes, function (d) {
                    return graphView === "horizontal" ? d?.x : d?.y;
                }) +
                    margin.top +
                    margin.bottom,
            );
            setTreeWidth(
                d3.max(nodes, function (d) {
                    return graphView === "horizontal" ? d?.y : d?.x;
                }) +
                    margin.left +
                    margin.right,
            );
            // Update the SVG element's height and width.
            svg.attr("height", treeHeight).attr("width", treeWidth);

            // Normalize for fixed-depth.
            nodes.forEach(function (d, i) {
                d.y = d.depth * (graphView === "horizontal" ? 300 : 150);
                let rndInt = Math.floor(Math.random() * 4) + 1;
                var random_boolean = Math.random() < 0.2;
                // 20% this will be true, 80% false
                d.isBlur = d?.isBlur ? d?.isBlur : random_boolean;
                d.type = d?.type ? d?.type : rndInt;
                d.id = d?.id ? d?.id : d.x + d.y + "_" + d.name + "-" + i;
            });

            findDepthValue = d3.max(nodes, function (d) {
                return d.depth;
            });
            onDepthChange(findDepthValue);

            // Update the nodes…
            var node = svg.selectAll("g.node").data(nodes, function (d) {
                return d.id;
            });

            // Enter any new nodes at the parent's previous position.
            var nodeEnter = node
                .enter()
                .append("g")
                .attr("class", function (d) {
                    return d.nodeShow
                        ? "node level-" + d.depth + " " + d.name
                        : "node level-" + d.depth + " " + d.name + "";
                })
                .attr("transform", function () {
                    return "translate(" + source.x0 + "," + source.y0 + ")";
                })
                .on("click", click);

            nodeEnter
                .append("rect")
                .attr("width", rectW)
                .attr("height", rectH)
                .attr("x", -rectW / 2) // offset by half the long dimension
                .attr("y", -rectH / 2) // offset by half the short dimension
                .attr("stroke", function (d) {
                    return d?.stageState
                        ? "#" + d?.stageState?.color_code
                        : "#808080";
                })
                .attr("class", function () {
                    return "rect-discription";
                })
                .attr("stroke-width", 2)
                .attr("rx", "5")
                .attr("ry", "5")
                .style("fill", function () {
                    return "rgba(255, 255, 255,.1)";
                });

            nodeEnter
                .append("rect")
                .attr("width", reactTNW)
                .attr("height", reactTNH)
                .attr("x", -115)
                .attr("y", -66)
                .attr("stroke-width", 1)
                .attr("class", function () {
                    return "rect-name";
                })
                .attr("stroke", function (d) {
                    return d?.stageState
                        ? "#" + d?.stageState?.color_code
                        : "#808080";
                })
                .attr("rx", "10")
                .attr("ry", "10")
                .style("fill", function (d) {
                    return d?.stageState
                        ? "#" + d?.stageState?.color_code
                        : "#808080";
                });

            nodeEnter
                .append("text")
                .attr("x", -(reactTNW / 2 + 12))
                .attr("y", -(reactTNH / 2 + 30))
                .attr("dy", ".35em")
                .attr("text-anchor", "middle")
                .style("fill", "#fff")
                .style("font-weight", "600")
                .style("opacity", "1")
                .attr("class", function () {
                    return "first-text";
                })
                // .text(function (d) {
                //     return  d?.stageState ? d?.stageState?.name?.length > 9 ? truncate(d?.stageState?.name, 8) : d?.stageState?.name : "default";
                // });
                .each(function (d) {
                    const textToHighlight = d?.stageState
                        ? d?.stageState?.name?.length > 9
                            ? truncate(d?.stageState?.name, 8)
                            : d?.stageState?.name
                        : "default";
                    const highlightedText = randerHighlighter(textToHighlight);
                    const highlighted =
                        contentSearch &&
                        textToHighlight
                            .toLowerCase()
                            .includes(contentSearch.toLowerCase());
                    d3.select(this).style(
                        "fill",
                        highlighted ? "yellow" : "#fff",
                    ); // Set text fill to red when highlighted
                    d3.select(this).text(
                        () => highlightedText?.props?.textToHighlight,
                    ); // Append the highlighted text
                });

            nodeEnter
                .append("text")
                .attr("x", 0)
                .attr("y", 0)
                .attr("dy", "0em")
                .attr("text-anchor", "middle")
                .style("font-weight", "600")
                .style("fill", "#000")
                .attr("class", function () {
                    return "second-text";
                })
                .each(function (d) {
                    const description = d?.name;
                    const words = description ? description.split(" ") : []; // Check if description is defined before splitting
                    const lines = [];
                    let currentLine = "";

                    words.forEach(word => {
                        if ((currentLine + word).length > 16) {
                            lines.push(currentLine.trim());
                            currentLine = "";
                        }
                        currentLine += word + " ";
                    });

                    if (currentLine.length > 0) {
                        lines.push(currentLine.trim());
                    }

                    lines.forEach((line, index) => {
                        const textToHighlight =
                            index < 1
                                ? line
                                : index === 1
                                ? truncate(line, 17)
                                : "";
                        const highlightedText =
                            randerHighlighter(textToHighlight);
                        const highlighted =
                            contentSearch &&
                            textToHighlight
                                .toLowerCase()
                                .includes(contentSearch.toLowerCase());
                        d3.select(this).style(
                            "fill",
                            highlighted ? "yellow" : "#000",
                        ); // Set text fill to red when highlighted
                        // Check if the text should be highlighted
                        d3.select(this)
                            .append("tspan")
                            .attr("x", -20)
                            .attr("dy", index === 0 ? 0 : "1.2em") // Adjust line spacing as needed
                            .attr("class", function () {
                                return "second-text-tspan";
                            })
                            .style("fill", highlighted ? "yellow" : "#000") // Set text fill to red when highlighted
                            .text(highlightedText?.props?.textToHighlight);
                        //.text(index < 1 ? line : index === 1 ? truncate(line, 17) : "");
                    });
                });

            nodeEnter
                .append("foreignObject")
                .attr("width", 20) // Adjust the width and height as per your icon size
                .attr("height", 20)
                .attr("x", 75) // Adjust the position of the icon
                .attr("y", -35)
                .attr("class", "icon-popup")
                .append("xhtml:div")
                .style("display", "flex") // To center the icon within the foreignObject
                .style("justify-content", "start")
                .style("align-items", "start")
                .style("opacity", 1)
                .html(() =>
                    ReactDOMServer.renderToString(
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            size="lg"
                            style={{
                                color: "#36a9e3",
                                borderRadius: "50%",
                                opacity: 1,
                            }}
                            className="icon-popup-image"
                        />,
                    ),
                );

            // Transition nodes to their new position.
            var nodeUpdate = node
                .transition()
                .duration(duration)
                .attr("transform", function (d) {
                    return graphView == "horizontal"
                        ? "translate(" + d.y + "," + d.x + ")"
                        : "translate(" + d.x + "," + d.y + ")";
                });

            nodeUpdate
                .select(".rect-discription")
                .attr("width", rectW)
                .attr("height", rectH)
                .attr("fill-opacity", function (d) {
                    if (
                        d?.stagesColorFlag == true ||
                        (openStageDetailFlagRef.current == false &&
                            d?.searchFilterFlag != false)
                    ) {
                        return 1;
                    } else {
                        return 0.2;
                    }
                })
                .attr("stroke-opacity", function (d) {
                    if (
                        d?.stagesColorFlag == true ||
                        (openStageDetailFlagRef.current == false &&
                            d?.searchFilterFlag != false)
                    ) {
                        return 1;
                    } else {
                        return 0.2;
                    }
                });

            nodeUpdate
                .select(".rect-name")
                .attr("fill-opacity", function (d) {
                    if (
                        d?.stagesColorFlag == true ||
                        (openStageDetailFlagRef.current == false &&
                            d?.searchFilterFlag != false)
                    ) {
                        return 1;
                    } else {
                        return 0.2;
                    }
                })
                .attr("stroke-opacity", function (d) {
                    if (
                        d?.stagesColorFlag == true ||
                        (openStageDetailFlagRef.current == false &&
                            d?.searchFilterFlag != false)
                    ) {
                        return 1;
                    } else {
                        return 0.2;
                    }
                });

            nodeUpdate.select(".first-text").attr("fill-opacity", function (d) {
                if (
                    d?.stagesColorFlag == true ||
                    (openStageDetailFlagRef.current == false &&
                        d?.searchFilterFlag != false)
                ) {
                    return 1;
                } else {
                    return 0.7;
                }
            });

            nodeUpdate
                .select(".second-text")
                .attr("fill-opacity", function (d) {
                    if (
                        d?.stagesColorFlag == true ||
                        (openStageDetailFlagRef.current == false &&
                            d?.searchFilterFlag != false)
                    ) {
                        return 1;
                    } else {
                        return 0.2;
                    }
                });

            nodeUpdate
                .select(".second-text")
                .attr("stroke-opacity", function (d) {
                    if (
                        d?.stagesColorFlag == true ||
                        (openStageDetailFlagRef.current == false &&
                            d?.searchFilterFlag != false)
                    ) {
                        return 1;
                    } else {
                        return 0.2;
                    }
                });

            nodeUpdate.select(".icon-popup").attr("opacity", function (d) {
                if (
                    d?.stagesColorFlag == true ||
                    (openStageDetailFlagRef.current == false &&
                        d?.searchFilterFlag != false)
                ) {
                    return 1;
                } else {
                    return 0.2;
                }
            });

            // Transition exiting nodes to the parent's new position.
            var nodeExit = node
                .exit()
                .transition()
                .duration(duration)
                .attr("transform", function () {
                    return graphView == "horizontal"
                        ? "translate(" + source.y + "," + source.x + ")"
                        : "translate(" + source.x + "," + source.y + ")";
                })
                .remove();

            nodeExit
                .select("rect")
                .attr("width", rectW)
                .attr("height", rectH)
                .attr("stroke", "black")
                .attr("stroke-width", 1);

            nodeExit.select("text");

            // Update the links…
            var link = svg.selectAll("path.link").data(links, function (d) {
                return d.target.id;
            });

            // Enter any new links at the parent's previous position.
            link.enter()
                .insert("path", "g")
                .attr("class", function (d) {
                    //return d?.target?.nodeShow ? selectedLevel?.level >= d.target.depth ? "link level-"+d.target.depth+" hide" : "link level-"+d.target.depth : "link level-"+d.target.depth+" hide";
                    return d?.target?.nodeShow
                        ? "link level-" + d.target.depth
                        : "link level-" + d.target.depth + "";
                })
                .attr("stroke", function () {
                    //return colorFlag ? d?.target?.stagesColorFlag ? d?.target?.stageState ? "#"+d?.target?.stageState?.color_code : "#ccc" : d?.target?.stageState ? "#"+d?.target?.stageState?.color_code+"66" : "#ccc" : d?.target?.stageState ? "#"+d?.target?.stageState?.color_code : "#ccc";
                    return "#c1cfdc";
                })
                .attr("stroke-opacity", function (d) {
                    //return  colorFlag ? d?.target?.stagesColorFlag ? 1 : .2 : 1;
                    if (
                        d?.stagesColorFlag == true ||
                        (openStageDetailFlagRef.current == false &&
                            d?.searchFilterFlag != false)
                    ) {
                        return 1;
                    } else {
                        return 0.2;
                    }
                })
                .attr("stroke-width", function (d) {
                    //return colorFlag ? d?.target?.stagesColorFlag ? 2.5 : 1.5 : 1.5;
                    if (
                        d?.stagesColorFlag == true ||
                        (openStageDetailFlagRef.current == false &&
                            d?.searchFilterFlag != false)
                    ) {
                        return 2.5;
                    } else {
                        return 1.5;
                    }
                })
                .attr("x", rectW / 2)
                .attr("y", rectH / 2)
                .attr("d", function () {
                    var o = {
                        x: graphView == "horizontal" ? source.y0 : source.x0,
                        y: graphView == "horizontal" ? source.x0 : source.y0,
                    };
                    return diagonal({
                        source: o,
                        target: o,
                    });
                });

            link.transition()
                .duration(duration)
                .attr("stroke", function () {
                    return "#c1cfdc";
                })
                .attr("stroke-opacity", function (d) {
                    if (
                        d?.target?.stagesColorFlag == true ||
                        (openStageDetailFlagRef.current == false &&
                            d?.target?.searchFilterFlag != false)
                    ) {
                        return 1;
                    } else {
                        return 0.5;
                    }
                })
                .attr("stroke-width", function (d) {
                    if (
                        d?.target?.stagesColorFlag == true ||
                        (openStageDetailFlagRef.current == false &&
                            d?.target?.searchFilterFlag != false)
                    ) {
                        return 2.5;
                    } else {
                        return 1.5;
                    }
                })
                .attr("d", function (d) {
                    let horizontal = {
                        source: { x: d.source.x, y: d.source.y + rectW / 2 }, // add half the rectangle length
                        target: { x: d.target.x, y: d.target.y - rectW / 2 }, // minus half the rectangle length
                    };
                    let vertical = {
                        source: { x: d.source.x, y: d.source.y + rectH / 2 }, // add half the rectangle length
                        target: { x: d.target.x, y: d.target.y - rectH / 2 }, // minus half the rectangle length
                    };

                    return diagonal(
                        graphView == "horizontal" ? horizontal : vertical,
                    );
                });

            // Transition exiting nodes to the parent's new position.
            link.exit()
                .transition()
                .duration(duration)
                .attr("d", function () {
                    var o = {
                        x: graphView == "horizontal" ? source.x : source.x,
                        y: graphView == "horizontal" ? source.y : source.y,
                    };
                    return diagonal({
                        source: o,
                        target: o,
                    });
                })
                .remove();

            // Stash the old positions for transition.
            nodes.forEach(function (d) {
                d.x0 = graphView == "horizontal" ? d.y : d.x;
                d.y0 = graphView == "horizontal" ? d.x : d.y;
            });
            setSvgDimensions();
            setTimeout(() => {
                setSvgDimensions();
            }, 1000);
        }

        function appendStagesParent(node, depthValue, flag) {
            if (type === "level") {
                if (node?.depth === 0) {
                    node.stagesColorFlag = true;
                } else {
                    node.stagesColorFlag = flag;
                }
            } else {
                node.stagesColorFlag = flag;
            }

            if (node?.depth >= 0 && depthValue >= 0 && node?.parent) {
                appendStagesParent(node?.parent, depthValue - 1, flag);
            }
        }

        // If the clicked node, open the foreignObject
        function appendForeignObject(clickedNode, yPosition) {
            clickedNode
                .append("foreignObject")
                .attr("x", graphView === "horizontal" ? yPosition : -500)
                .attr("y", graphView === "horizontal" ? -350 : yPosition)
                .attr("width", graphView === "horizontal" ? 700 : 1000) // Adjust the width to fit your content
                .attr("height", graphView === "horizontal" ? 800 : 600) // Adjust the height to fit your content
                .attr("class", function (d) {
                    return graphView === "horizontal"
                        ? d?.stageDetailFlag
                            ? "user-details horizontal-details foreignObject"
                            : "user-details horizontal-details disabled foreignObject"
                        : d?.stageDetailFlag
                        ? "user-details vertical-details foreignObject"
                        : "user-details vertical-details disabled foreignObject";
                })
                .append("xhtml:div")
                .style("color", "black")
                .style("font-weight", "700")
                .style("opacity", function () {
                    return "1"; //d?.stageDetailFlag ? "1" : "0"; // Set opacity to 1 to make the text visible
                })
                .html(function (d) {
                    let imageFlag = d?.stateHistories?.length
                        ? d?.stateHistories[0]?.userData?.profileimage
                            ? true
                            : false
                        : false;
                    let nameFlag = d?.stateHistories?.length
                        ? d?.stateHistories[0]?.userData?.firstname
                            ? true
                            : false
                        : false;
                    let userSmImgContent;
                    if (imageFlag) {
                        userSmImgContent = "<img src='' />";
                    } else if (nameFlag) {
                        userSmImgContent = `<img src=${userProfile} />`;
                    } else {
                        userSmImgContent = `<img src=${userProfile} />`;
                    }

                    const dateString = d?.created_at;
                    const date = new Date(dateString);

                    // Format the date
                    const formattedDate = `${date.getDate()}.${
                        date.getMonth() + 1
                    }.${date.getFullYear()}`;
                    const formattedTime = `${date.getHours()}:${date.getMinutes()}`;
                    const formattedDateTime = `${formattedDate} | ${formattedTime}`;

                    return `<div class="custom-box">
                          <div class="connector" style="height: ${
                              graphView === "horizontal"
                                  ? 2.5
                                  : yPosition + 10 - rectH / 2
                          }px;width: ${graphView === "horizontal" ? yPosition + 10 - rectW / 2 : 2.8}px; top: ${graphView === "horizontal" ? 340 : null}px;"></div>
                          <div class="modal_head">
                              <div class="d-flex justify-content-between align-items-center">
                                  <div class="d-flex align-items-center">
                                      <div>
                                          <div class="doc_img">
                                              <img src="${WordFile}" alt="img" />
                                          </div>
                                      </div>
                                      <div>
                                          <h3>${d?.name ? d?.name : ""}</h3>
                                          <p>V.14 (Current Version)</p>
                                      </div>
                                  </div>
                                  <div class="right_arrow">   
                                  <img src="${RightArrow}" alt="img" />                                
                                  </div>
                              </div>
                          </div>
                          <hr />
                          <div class="modal_body">
                              <div class="row vertical">
                                  <div class="col-5">
                                      <table class="table table-borderless">
                                          <tr>
                                              <td>
                                                  <h6 class="mb-0">Durum :</h6>
                                              </td>
                                              <td>
                                                  <button class="btn btn-warning">${
                                                      d?.stageState
                                                          ? d?.stageState?.name
                                                          : "default"
                                                  }</button>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <h6 class="mb-0">Ekleyen Kullanici :</h6>
                                              </td>
                                              <td>
                                                  <button class="btn btn-default mb-1 text-left">
                                                    <span class="user-sm-img">
                                                    ${userSmImgContent}
                                                    </span>
                                                    <strong>${
                                                        d?.stateHistories
                                                            ?.length
                                                            ? d
                                                                  ?.stateHistories[0]
                                                                  ?.userData
                                                                ? d
                                                                      ?.stateHistories[0]
                                                                      ?.userData
                                                                      ?.firstname +
                                                                  " " +
                                                                  d
                                                                      ?.stateHistories[0]
                                                                      ?.userData
                                                                      ?.lastname +
                                                                  " " +
                                                                  d
                                                                      ?.stateHistories[0]
                                                                      ?.userData
                                                                      ?.role
                                                                : "default"
                                                            : "default"
                                                    }</strong>
                                                  </button>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <h6 class="mb-0">Ekleme Tarihi :</h6>
                                              </td>
                                              <td>
                                                  <button class="btn btn-default mb-1"><strong>${formattedDateTime}</strong></button>
                                              </td>
                                          </tr>
                                          <tr>
                                          <td>
                                              <h6 class="mb-0">Dokuman Tipi :</h6>
                                          </td>
                                          <td>
                                              <button class="btn btn-default btn-default1 mb-1">Sozlesme</button>
                                          </td>
                                      </tr>
                                          <tr>
                                              <td>
                                                  <h6 class="mb-0">Versiyon :</h6>
                                              </td>
                                              <td>
                                                  <button class="btn btn-default btn-default1 mb-1">${
                                                      d?.stage_type
                                                          ? d?.stage_type
                                                          : ""
                                                  } <strong>${d?.type ? d?.type : ""}</strong></button>
                                              </td>
                                          </tr>
                                      </table>
                                  </div>
                                  <div class="col-7">
                                      <h6 class="mb-4 mt-2">Versiyon Aciklamasi : </h6>
                                      <p class="font-normal small"> ${
                                          d?.description ? d?.description : ""
                                      }</p>
                                  </div>
                              </div>
                          </div>
                      </div>`;
                });
        }

        // Toggle children on click.
        function click(d) {
            // Check if the clicked element is the icon
            const isIconClicked =
                d3?.event?.target?.nearestViewportElement?.classList.contains(
                    "icon-popup",
                ); // 'icon-popup' the actual class name of the icon with help of we find nearest class where we click
            const isIconClickedImage =
                d3?.event?.target?.nearestViewportElement?.classList.contains(
                    "icon-popup-image",
                );
            if (isIconClicked || isIconClickedImage) {
                if (d?.id == iconNodeRef.current?.id) {
                    // If the clicked node is the same as the opened node, close the foreignObject
                    setShowIconPopup(false);
                    setIconNode({});
                } else {
                    setShowIconPopup(true);
                    d.clientX =
                        graphView === "horizontal"
                            ? d3?.event.clientX + 15
                            : d3?.event.clientX - 280;
                    d.clientY =
                        graphView === "horizontal"
                            ? d3?.event.clientY - 180
                            : d3?.event.clientY + 60;
                    setIconNode(d);
                    setSvgDimensions();
                }
                return true;
            }

            if (clickEventFind == "mousemove") {
                clickEventFind = "mouse";
                return false;
            }
            if (
                (selected.length > 0 ||
                    selectedStage.length > 0 ||
                    selectedFolder?.length ||
                    selectedMultiOptions?.length) &&
                d.searchFilterFlag == false &&
                d._children?.length > 0
            ) {
                return false;
            }
            if (!d?.children?.length && !d?._children?.length) {
                setShowIconPopup(false);
                setIconNode({});
                if (openedNode === this) {
                    d.stageDetailFlag = false;
                    setOpenStageDetailFlag(false);
                    d.stagesColorFlag = false;
                    if (d?.parent !== null) {
                        appendStagesParent(d?.parent, d?.depth - 1, false);
                    }
                    // If the clicked node is the same as the opened node, close the foreignObject
                    removeOrHideForeignObject(openedNode, "detail-popup");
                    openedNode = null;
                    openedNodeValue = {};
                } else {
                    d.stageDetailFlag = true;
                    setOpenStageDetailFlag(true);
                    var nodes = tree.nodes(data).reverse();
                    var yPosition = d3.max(nodes, function (d) {
                        return d.y;
                    });
                    yPosition =
                        graphView === "horizontal"
                            ? yPosition + 300
                            : yPosition + 150;
                    if (openedNode) {
                        if (openedNodeValue?.stagesColorFlag) {
                            openedNodeValue.stageDetailFlag = false;
                            openedNodeValue.stagesColorFlag = false;
                            if (openedNodeValue?.parent !== null) {
                                appendStagesParent(
                                    openedNodeValue?.parent,
                                    openedNodeValue?.depth - 1,
                                    false,
                                );
                            }
                            openedNodeValue = {};
                        }
                        // If there is an open foreignObject, remove or hide it
                        removeOrHideForeignObject(openedNode, "detail-popup");
                    }
                    d.stagesColorFlag = true;
                    //d3.select(d.x+d.y).remove();
                    if (d?.parent !== null) {
                        appendStagesParent(d?.parent, d?.depth - 1, true);
                    }
                    appendForeignObject(d3.select(this), yPosition);
                    openedNode = this; // Set the clicked node as the currently opened node
                    openedNodeValue = d;
                    setSvgDimensions();
                }

                update(data, d?.stagesColorFlag);
                return true;
            } else {
                setShowIconPopup(false);
                setIconNode({});
                if (openedNodeValue?.stageDetailFlag) {
                    setOpenStageDetailFlag(false);
                    openedNodeValue.stageDetailFlag = false;
                    openedNodeValue.stagesColorFlag = false;
                    if (d?.parent !== null) {
                        appendStagesParent(
                            openedNodeValue?.parent,
                            openedNodeValue?.depth - 1,
                            false,
                        );
                    }
                    openedNodeValue = {};
                    // If there is an open foreignObject, remove or hide it
                    removeOrHideForeignObject(openedNode, "detail-popup");
                    openedNode = null;
                }
                if (d.children) {
                    if (d.children?.length) {
                        d.children.forEach(
                            element => (element.nodeShow = false),
                        );
                    }
                    d._children = d.children;
                    d.nodeShow = true;
                    d.children = null;
                } else {
                    if (d._children?.length) {
                        d._children.forEach(
                            element => (element.nodeShow = true),
                        );
                    }
                    d.children = d._children;
                    d.nodeShow = true;
                    d._children = null;
                }
                update(d);
                return true;
            }
        }

        // This function is used to truncate the text of each node label to a certain length.
        function truncate(str, length) {
            return str.slice(0, length) + "...";
        }

        //Redraw for zoom
        function redraw() {
            setShowIconPopup(false);
            setIconNode({});
            if (d3.event.sourceEvent && d3.event.sourceEvent.type === "wheel") {
                // Check if the event is triggered by mouse wheel scrolling
                return;
            }
            if (d3.event.sourceEvent === null) {
                return;
            }
            clickEventFind = d3.event.sourceEvent.type;

            onTranslateUpdate(d3.event.translate, "svgTranslate");
            setSvgTranslateChange(d3.event.translate);
        }

        function zoomEndFunction() {
            setTimeout(function () {
                clickEventFind = "mouse";
            }, 10);
        }

        // Calculate the height and width of the SVG
        function setSvgDimensions() {
            var svgWidth = svg.node().getBoundingClientRect().width;
            var svgHeight = svg.node().getBoundingClientRect().height;
            //console.log("svgWidth-svgHeight",svgWidth,svgHeight);
            onTranslateUpdate(svgHeight, "svgHeight");
            onTranslateUpdate(svgWidth, "svgWidth");

            setchartHeight(svgHeight);
            setchartWidth(svgWidth);

            if (graphRef?.current !== undefined && graphRef?.current !== null) {
                const visualisationNode = graphRef.current;
                const gElement = visualisationNode.querySelector("div svg g");
                if (gElement !== null) {
                    const transform = d3.transform(
                        gElement.getAttribute("transform"),
                    );
                    const translateX = transform.translate[0];
                    const translateY = transform.translate[1];
                    onTranslateUpdate([translateX, translateY], "svgTranslate");
                }
            }
        }
    };

    // Calculate the height and width of the SVG
    function setTranslateDimensions() {
        if (graphRef?.current !== undefined && graphRef?.current !== null) {
            const visualisationNode = graphRef.current;
            const gElement = visualisationNode.querySelector("div svg g");
            if (gElement !== null) {
                const transform = d3.transform(
                    gElement.getAttribute("transform"),
                );
                const translateX = transform.translate[0];
                const translateY = transform.translate[1];
                onTranslateUpdate([translateX, translateY], "svgTranslate");
            }
        }
    }

    //Remove or hide the foreignObject associated with the given node
    function removeOrHideForeignObject(node, type) {
        console.log(openStageDetailFlag); //this need to be include for use react-usestateref package, othewise es lint throw error
        if (type === "detail-popup") {
            d3.select(node).select(".foreignObject").remove();
        } else {
            d3.select(node).select(".foreignObject-popup").remove();
        }
    }

    return (
        <div>
            <div
                className={
                    isLoading
                        ? graphView === "horizontal"
                            ? "horizontal-align disabled"
                            : "vertical-align disabled"
                        : graphView === "horizontal"
                        ? "horizontal-align"
                        : "vertical-align"
                }
                ref={graphRef}
                //style={{height: chartHeight ? graphView === "horizontal" ? chartHeight > windowDimensions.height ? chartHeight : chartHeight + (windowDimensions.height - (chartHeight-100)) : windowDimensions.height : windowDimensions.height , width:chartWidth ? graphView === "horizontal" ? chartWidth + windowDimensions.width : chartWidth > windowDimensions.width ? chartWidth : windowDimensions.width : windowDimensions.width}}
                style={{
                    height: chartHeightRef.current
                        ? chartHeightRef.current > windowDimensions.height
                            ? chartHeightRef.current
                            : windowDimensions.height
                        : windowDimensions.height,
                    width: chartWidthRef.current
                        ? chartWidthRef.current > windowDimensions.width
                            ? chartWidthRef.current
                            : windowDimensions.width
                        : windowDimensions.width,
                }}
                id="tree-chart"></div>
            {graphView === "horizontal" ? (
                <div
                    className="iconPopup"
                    id="iconPopupId"
                    style={{
                        left: iconNode?.clientX,
                        top: iconNode?.clientY,
                        transform:
                            "scale(" +
                            scaleHorizontalTransform[zoomValue].scale +
                            ") translate(" +
                            scaleHorizontalTransform[zoomValue].x +
                            "," +
                            scaleHorizontalTransform[zoomValue].y +
                            ")",
                    }}>
                    {showIconPopup ? (
                        <IconPopup
                            showIconPopup={showIconPopup}
                            iconNode={iconNode}
                        />
                    ) : null}
                </div>
            ) : (
                <div
                    className="iconPopup"
                    id="iconPopupId"
                    style={{
                        left: iconNode?.clientX,
                        top: iconNode?.clientY,
                        transform:
                            "scale(" +
                            scaleTransform[zoomValue].scale +
                            ") translate(-13%," +
                            scaleTransform[zoomValue].y +
                            ")",
                    }}>
                    {showIconPopup ? (
                        <IconPopup
                            showIconPopup={showIconPopup}
                            iconNode={iconNode}
                        />
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default TreeChart;


