import React,{ useEffect,useRef } from "react";
import useState from "react-usestateref";
import TreeChart from "./TreeChart";
import Header from "./Header.js";
// import Zoom from "../../../../assets/images/zoom.svg";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import stages from "./stages.json";
import * as d3 from "d3";
import Loader from "./Loader";

//Find the total height and width of screen
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
}

var ZoomScale = 5;
var transValue = [];

function Index() {

	const windowDimensions = getWindowDimensions();

	const [ graphView,setGraphView,graphViewRef ] = useState("vertical");
	const [ flag,setFlag ] = useState(false);
	const [ items, setItems ] = useState({});
	const [transform, setTransform] = useState([{ name: "zoom-1", percent: 50, scale:0.3 }, { name: "zoom-2", percent: 60, scale:0.45}, { name: "zoom-3", percent: 70 , scale:0.60}, { name: "zoom-4", percent: 80, scale:0.75 }, { name: "zoom-5", percent: 90, scale:0.90 }, { name: "zoom0", percent: 100, scale:1 }, { name: "zoom1", percent: 110, scale:1.4 }, { name: "zoom2", percent: 120 , scale:1.8}, { name: "zoom3", percent: 130, scale:2.2 }, { name: "zoom4", percent: 140 , scale:2.6}, { name: "zoom5", percent: 150, scale:3 }]);
	const [zoom, setZoom] = useState(ZoomScale);
	const [translateValue, setTranslateValue] = useState(transValue.length !==0 ? transValue : [ windowDimensions?.width / 2,180 ]);
	const [svgWidth, setSvgWidth,svgWidthRef] = useState(0);
    const [svgHeight, setSvgHeight,svgHeightRef] = useState(0);
	const [depth, setDepth] = useState(null);
	const [depthCheck, setDepthCheck] = useState(false);
	const [depthType, setDepthType] = useState(null);
	const [maxDepth, setMaxDepth] = useState(null);
	const [filterFlag, setFilterFlag] = useState(false);
	const [filterDate, setFilterDate] = useState({});
	const [contentSearch, setContentSearch] = useState("");
	const [selected, setSelected] = useState([]);
	const [selectedStage, setSelectedStage] = useState([]);
	const [selectedFolder, setSelectedFolder] = useState([]);
	const [filterApplyFlag, setFilterApplyFlag] = useState(false);
	const [filterClear, setFilterClear] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [searchFilter,setSearchFilter] = useState(false);
	const [oldSelected, setOldSelected] = useState([]);
	const [oldSelectedStage, setOldSelectedStage] = useState([]);
	const [oldSelectedFolder, setOldSelectedFolder] = useState([]);
	const [graphData, setGraphData] = useState({});
	const [imageFlag,setImageFlag] = useState(false);
	const [filterCount,setFilterCount] = useState(0);
	const [allOption,setAllOption] = useState([]);
	const [selectedMultiOptions, setSelectedMultiOptions] = useState([]);
	const [oldSelectedMultiOptions, setOldSelectedMultiOptions] = useState([]);

	const visualisationRef = useRef(null);

	const getAllRecord=()=>{
       //There we call API

	   //After getting Api response, we prepare chart data with the help of this function
		preparChartData(stages);
	
	};
	
	//data prepare for graph
	const preparChartData=(response)=>{
		if(response.status === "success" && response.stages.length ){
			response.children = [];
			response.stages.map((item)=>{
				item.nodeShow = false;
				response.children.push(item);
			});
			response.name = response.stages[0].name;
			response.description = response.stages[0].description;
			response.id = "001-stages";
			response.stageState = response.stages[0].stageState;
			response.nodeShow = false;
		}
		const maxDepthValue = findDepth(response);
		setMaxDepth(maxDepthValue);
		setGraphData(response);
		setTransform([{ name: "zoom-1", percent: 50, scale:0.3 }, { name: "zoom-2", percent: 60, scale:0.45}, { name: "zoom-3", percent: 70 , scale:0.60}, { name: "zoom-4", percent: 80, scale:0.75 }, { name: "zoom-5", percent: 90, scale:0.90 }, { name: "zoom0", percent: 100, scale:1 }, { name: "zoom1", percent: 110, scale:1.4 }, { name: "zoom2", percent: 120 , scale:1.8}, { name: "zoom3", percent: 130, scale:2.2 }, { name: "zoom4", percent: 140 , scale:2.6}, { name: "zoom5", percent: 150, scale:3 }]);
	};

	useEffect(()=>{
		// This function use for called Api and get all stages.
		getAllRecord();
	},[]);

	useEffect(() => {
		if(visualisationRef?.current !== undefined && visualisationRef?.current !== null){
			const visualisationNode = visualisationRef.current;
			const gElement = visualisationNode.querySelector("div div svg g");
			if(gElement !== null){
				gElement.setAttribute("transform", `translate(${translateValue}) scale(${transform[zoom].scale})`);
			}
		}
    }, [zoom,translateValue]);

	//This function use for find maximum depth of chart
	function findDepth(node, depth = 0) {
		if (node.children) {
		  let maxDepth = depth;
		  node.children.forEach((child) => {
			const childDepth = findDepth(child, depth + 1);
			maxDepth = Math.max(maxDepth, childDepth);
		  });
		  return maxDepth;
		} else {
		  return depth;
		}
	}
	
	//This function use for change the orientation of graph
	const graphOrientation=(view)=>{
		setGraphView(view);
		setFlag(true);
		setFilterFlag(false);
	};

	//Use for set option value of dropdown
	const onSelect = (option) => {
		setItems(option);
		setFlag(true);
	};

	//This function used for handle transform of graph
	const handleZoom = (type) => {
		if (type == "up") {
			if (zoom < transform.length - 1) {
				if(visualisationRef?.current !== undefined && visualisationRef?.current !== null){
					const visualisationNode = visualisationRef.current;
					const gElement = visualisationNode.querySelector("div div svg g");
					if(gElement !== null){
						const transform = d3.transform(gElement.getAttribute("transform"));
						const translateX = graphView === "horizontal" ? transform.translate[0] : transform.translate[0]+100;
						const translateY = graphView === "horizontal" ? transform.translate[1]+100 : transform.translate[1] ;
						gElement.setAttribute("transform", `translate(${translateX}, ${translateY})`);
					}
				}
				setZoom(zoom + 1);
				ZoomScale = zoom + 1;
			}
		}
		else {
			if (zoom > 0.3) {
				if(visualisationRef?.current !== undefined && visualisationRef?.current !== null){
					const visualisationNode = visualisationRef.current;
					const gElement = visualisationNode.querySelector("div div svg g");
					if(gElement !== null){
						const transform = d3.transform(gElement.getAttribute("transform"));
						const translateX = graphView === "horizontal" ? transform.translate[0] : transform.translate[0]-100;
						const translateY = graphView === "horizontal" ? transform.translate[1]-100 : transform.translate[1] ;
						gElement.setAttribute("transform", `translate(${translateX}, ${translateY})`);
					}
				}
				setZoom(zoom - 1);
				ZoomScale = zoom - 1;		
			}
		}
	};

	//This function use for any update in graph translate
	const handleTranslateUpdate = (value,type) => {
		if(type === "svgTranslate" && value !== undefined){
			setTranslateValue(value);
			transValue = value;
		}
		
		if(type === "svgHeight"){
			setSvgHeight(value);
		}

		if(type === "svgWidth"){
			setSvgWidth(value);
		}

		if(type === "zoom"){
			ZoomScale = value;
			setZoom(value);
		}
	};

	const handleDepthValue = (value) => {
		setDepth(null);
		setDepth(items?.level === 2 ? value+1 : value);
	};
	
	//This function call for manage level data show on graph
	const handleDepthChange = (type) => {
		setDepthType(type);
		setDepthCheck(!depthCheck);
	};

	//This function called when clicked on export png.
	const onClickHandler = () => {
		setIsLoading(true);
		if(visualisationRef?.current !== undefined && visualisationRef?.current !== null){
			
			const visualisationNode = visualisationRef.current;
			const gElement = visualisationNode.querySelector("div div svg g");
			var divs =visualisationNode.querySelectorAll("div div svg g g");
			let translateX = graphViewRef.current === "horizontal" ? 140 : 0;
			let translateY = graphViewRef.current === "horizontal" ? 0 : 180;
			let arr=[];

			for (var i = 0; i < divs.length; ++i) {
				var newsa=divs[i].getAttribute("transform").split(",");	
				if(graphViewRef.current === "horizontal"){
					newsa=newsa[1].replace(")","");
				}else{
					newsa=newsa[0].replace("translate(","");
				}
				arr.push(newsa);
			}

			if(graphViewRef.current === "horizontal"){
				translateY = Math.min(...arr);
				if(translateY<1)
				{
					translateY=-(translateY);
				}
				translateY+=150;
			}else{
				translateX = Math.min(...arr);
				if(translateX<1)
				{
					translateX=-(translateX);
				}
				translateX+=150;
			}
						
			// To center the chart
			gElement.setAttribute("transform", `translate(${translateX}, ${translateY}) scale(1)`);

			// Wait for the browser to update the rendering
			setTimeout(() => {
				// Retrieve the new bounding box properties of the graph container element
				const newGraphRect = gElement.getBoundingClientRect();
				const newGraphWidth = newGraphRect.width;
				const newGraphHeight = newGraphRect.height;

				// Use the newGraphWidth and newGraphHeight as needed
				
				setSvgHeight(newGraphHeight+200);
				setSvgWidth(newGraphWidth+200);
				setImageFlag(true);
				// Image Download event handling logic...

				window.scrollTo(0, 0);

				// Calculate the bounding box of the graph
				const graphBoundingBox = gElement.getBBox();

				// Define the desired maximum dimensions based on the graph's bounding box (adjust these as needed)
				const maxWidth = Math.min(graphViewRef.current === "horizontal" ? 1000 : 2000, graphBoundingBox.width);
				const maxHeight = Math.min(graphViewRef.current === "horizontal" ? 2000 : 1000, graphBoundingBox.height);
			
				// Calculate the scale value based on the ratio between the actual dimensions and the maximum dimensions
				const scaleX = maxWidth / newGraphWidth;
				const scaleY = maxHeight / newGraphHeight;
			
				// Choose the smaller scale value to maintain aspect ratio
				var scale = Math.min(scaleX, scaleY);
				scale = scale*10;

				if(scale > 3){
					scale = 3;
				}

				setTimeout(() => {
					html2canvas(visualisationNode, {
						//scrollY: -window.scrollY,
						scrollY: graphViewRef.current === "horizontal" ? -window.scrollY : -window.scrollX,
						scale: scale, // Set the scale value
						useCORS: true, // Enable CORS to capture cross-origin images
						logging: true, // Enable logging for debugging (optional)
					}).then((canvas) => {
						canvas.toBlob((blob) => {
						saveAs(blob, "Stages-Map.png");
						// Reset the  chart back to it's previous position
						gElement.setAttribute("transform", `translate(${translateValue}) scale(${transform[ZoomScale].scale})`);
						setIsLoading(false);
						setImageFlag(false);
						});
					}).catch((err)=>{
						console.log(err);
						alert("Failed to capture image. Please try again later.");
						setIsLoading(false);
						setImageFlag(false);
					});
				}, 5000);
			}, 1000); // Use a small delay of 0 milliseconds to allow the browser to update the rendering
		}
	  };

	const filterFunction = (flag=false,clear="") => {
		if(flag){
			setSelected([]);
			setSelectedStage([]);
			setSelectedFolder([]);
			setSelectedMultiOptions([]);
			setFilterCount(0);
			setContentSearch("");
		}
		setFilterFlag(!filterFlag);
		
		if(clear === "clear"){
			setSearchFilter(false);
			setOldSelected([]);
			setOldSelectedStage([]);
			setOldSelectedFolder([]);
			setOldSelectedMultiOptions([]);
			setFilterCount(0);
			setFlag(true);
			setFilterClear(clear);
		}else{
			setFilterClear("");
		}
	};

	const getFilterData = (data,type) => {
		if(type=="date"){
			setFilterDate(data);
		}
		if(type == "content_search"){
			setContentSearch(data);
		}
		if (type == "dropdownValue") {
			console.log("dropdownValue", data);
			setSelected(data);
		}
		if(type == "stageValue"){
			setSelectedStage(data);
		}
		if(type == "folderValue"){
			setSelectedFolder(data);
		}
		if (type == "multiSelectDropdownValue") {
            setSelectedMultiOptions(data);
        }
		
	};

	//Function used when we apply filter
	const filterApply = (flag) => {
		let count = 0;
		setOldSelected(selected);
		setOldSelectedStage(selectedStage);
		setOldSelectedFolder(selectedFolder);
		setOldSelectedMultiOptions(selectedMultiOptions);

		if(selected.length){
			count = count+1;
		}
		if(selectedStage.length){
			count = count+1;
		}
		if(selectedFolder.length){
			count = count+1;
		}
		if (selectedMultiOptions.length) {
            count = count + 1;
        }
		setFilterCount(count);
		setFilterFlag(!filterFlag);
		setFilterClear("");
		setFilterApplyFlag(flag);
		setTimeout(() => {
			setFilterApplyFlag(false);
		}, 2000);
		if (
            selected.length ||
            selectedStage.length ||
            selectedFolder.length ||
            selectedMultiOptions.length
        ) {
            setSearchFilter(true);
        } else {
            setSearchFilter(false);
        }	
	};
	  
	return (
        <div className="stagesmap">
            <Loader isLoading={isLoading} />
            <Header
                graphOrientation={view => graphOrientation(view)}
                graphView={graphView}
                onSelect={onSelect}
                selectedValue={items}
                onClickHandler={onClickHandler}
                filterFunction={(flag, clear) => filterFunction(flag, clear)}
                filterFlag={filterFlag}
                getFilterData={(data, type) => getFilterData(data, type)}
                contentSearch={contentSearch}
                filterDropdownOption={stages}
                filterApply={flag => filterApply(flag)}
                setFilterFlag={setFilterFlag}
                oldSelected={oldSelected}
                oldSelectedStage={oldSelectedStage}
                oldSelectedFolder={oldSelectedFolder}
                filterCount={filterCount}
                setAllOption={setAllOption}
                oldSelectedMultiOptions={oldSelectedMultiOptions}
            />
            <div
                ref={visualisationRef}
                style={{
                    height: svgHeight
                        ? svgHeight > windowDimensions.height
                            ? svgHeight
                            : windowDimensions.height
                        : windowDimensions.height,
                    width: svgWidth
                        ? svgWidth > windowDimensions.width
                            ? svgWidth
                            : windowDimensions.width
                        : windowDimensions.width,
                }}>
                {graphData?.children?.length || graphData?._children?.length ? (
                    <TreeChart
                        data={graphData}
                        graphView={graphView}
                        flag={flag}
                        selectedLevel={items?.value ? items : {}}
                        zoomValue={zoom}
                        scaleValue={transform}
                        onTranslateUpdate={(value, type) =>
                            handleTranslateUpdate(value, type)
                        }
                        depthProp={depth}
                        onDepthChange={value => handleDepthValue(value)}
                        depthType={depthType}
                        depthCheck={depthCheck}
                        maxDepth={maxDepth}
                        translateValue={translateValue}
                        contentSearch={contentSearch}
                        filterDate={filterDate}
                        selected={searchFilter ? oldSelected : []}
                        selectedStage={searchFilter ? oldSelectedStage : []}
                        selectedFolder={searchFilter ? oldSelectedFolder : []}
                        filterApplyFlag={filterApplyFlag}
                        filterClear={filterClear}
                        isLoading={isLoading}
                        svgHeight={svgHeightRef.current}
                        svgWidth={svgWidthRef.current}
                        imageFlag={imageFlag}
                        allOption={allOption}
                        selectedMultiOptions={searchFilter ? oldSelectedMultiOptions:[]}
                    />
                ) : null}
            </div>

            {/* zoom option */}
            <div className="page_option">
                <div className="arrange_horrizontal">
                    <div className="zoom mx-1">
                        <a
                            href="javascript:;"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Zoom Out"
                            className={zoom == 0 ? "disabledCursor" : ""}
                            onClick={() => {
                                handleZoom("down"), setFilterFlag(false);
                            }}>
                            -
                        </a>
                        <div className="mx-1">
                            <a href="#">{transform[zoom].percent}%</a>
                        </div>
                        <a
                            href="javascript:;"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Zoom In"
                            className={
                                zoom == transform.length - 1
                                    ? "disabledCursor"
                                    : ""
                            }
                            onClick={() => {
                                handleZoom("up"), setFilterFlag(false);
                            }}>
                            +
                        </a>
                    </div>
                    <div
                        className="zoom up_down mx-1"
                        style={{ background: "black" }}>
                        <a
                            href="javascript:;"
                            className={searchFilter ? "disabledCursor" : ""}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Level Down"
                            onClick={() => {
                                depth === 0
                                    ? null
                                    : depth > (items?.level ? items?.level : 0)
                                    ? searchFilter
                                        ? null
                                        : handleDepthChange("down")
                                    : null,
                                    setFilterFlag(false);
                            }}>
                            -
                        </a>
                        <a
                            href="javascript:;"
                            className={searchFilter ? "disabledCursor" : ""}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Level Up"
                            onClick={() => {
                                maxDepth === depth
                                    ? null
                                    : searchFilter
                                    ? null
                                    : handleDepthChange("up"),
                                    setFilterFlag(false);
                            }}>
                            +
                        </a>
                    </div>
                    {/* <div className="zoom fullscreen mx-1" style={{background:"black"}}>
						<a href="javascript:;"><img src={Zoom} /></a>
					</div> */}
                </div>
            </div>
        </div>
    );
}
export default Index;