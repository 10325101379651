import React from "react";
import Logo from "../../../../assets/images/logo.svg";
import FilterImg from "../../../../assets/images/filter.svg";
import Dropdown from "react-bootstrap/Dropdown";
import HorizontalBlack from "../../../../assets/images/horizontal-black.svg";
import VerticalBlue from "../../../../assets/images/vertical-blue.svg";
import HorizontalBlue from "../../../../assets/images/horizontal-blue.svg";
import VerticalBlack from "../../../../assets/images/vertical-black.svg";
import Filter from "./Filter.js";
import Select, { components } from "react-select";
import { FaCheck } from "react-icons/fa";

const Header = ({
    graphOrientation,
    graphView,
    onSelect,
    selectedValue,
    onClickHandler,
    filterFunction,
    filterFlag,
    getFilterData,
    contentSearch,
    filterDropdownOption,
    filterApply,
    setFilterFlag,
    oldSelected,
    oldSelectedStage,
    oldSelectedFolder,
    filterCount,
    setAllOption,
    oldSelectedMultiOptions,
}) => {
    const options = [
        { value: 1, label: "Hiyerarsi/Maumele", level: 0 },
        { value: 2, label: "Demand (Talep)", level: 1 },
        { value: 3, label: "Objection (itiraz)", level: 2 },
    ];

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            paddingLeft: "24px",
            backgroundColor: state.isSelected
                ? "rgba(25, 138, 66, 0.05)"
                : "white",
            color: "black",
            fontWeight: state.isSelected ? 600 : 400,
        }),
        valueContainer: provided => ({
            ...provided,
            paddingRight: "30px", // Adjust the padding to accommodate the icon
        }),
        iconContainer: provided => ({
            ...provided,
            flex: 1,
            display: "flex",
            position: "absolute",
            alignItems: "center",
            top: "75%",
            right: "10px",
            transform: "translateY(-50%)",
            overflow: "hidden",
            padding: "2px 8px",
            boxSizing: "border-box",
            paddingRight: "30px",
        }),
        checkIcon: {
            color: "green", // Set the color of the FaCheck icon
        },
    };

    const Option = props => {
        const { data, isSelected } = props;
        return (
            <components.Option {...props}>
                {data.label}
                {isSelected && (
                    <FaCheck style={{ marginLeft: "20px", color: "#198a42" }} />
                )}
            </components.Option>
        );
    };

    return (
        <div className="top_strip">
            <div className="top_strip-arrange">
                <div className="brand_logo">
                    <a href="#">
                        <img src={Logo} alt="logo" title="logo" />
                        <span>Asamalar Atlası</span>
                    </a>
                </div>

                <div className="right_menu">
                    <div className="top_right-action">
                        <div>
                            <a
                                href="#"
                                className={
                                    graphView === "vertical"
                                        ? "btn btn-black active mx-1"
                                        : "btn btn-black mx-1"
                                }
                                onClick={() => graphOrientation("vertical")}>
                                <img
                                    src={
                                        graphView === "vertical"
                                            ? VerticalBlue
                                            : VerticalBlack
                                    }
                                    alt="Vertical"
                                    title="Vertical"
                                />
                                <span>Vertical View</span>
                            </a>
                        </div>
                        <div>
                            <a
                                href="#"
                                className={
                                    graphView === "horizontal"
                                        ? "btn btn-black active mx-1"
                                        : "btn btn-black mx-1"
                                }
                                onClick={() => graphOrientation("horizontal")}>
                                <img
                                    src={
                                        graphView === "horizontal"
                                            ? HorizontalBlue
                                            : HorizontalBlack
                                    }
                                    alt="Horizontal"
                                    title="Horizontal"
                                />
                                <span>Horizontal View</span>
                            </a>
                        </div>
                        <div>
                            <div
                                className="wrapper-dropdown lg mx-1"
                                onClick={() => setFilterFlag(false)}>
                                <Select
                                    options={options}
                                    value={
                                        selectedValue?.value
                                            ? selectedValue
                                            : ""
                                    }
                                    onChange={onSelect}
                                    styles={customStyles}
                                    components={{ Option }}
                                    placeholder={"All Arg Type"}
                                />
                            </div>
                        </div>
                        <div className="divider mx-2">&nbsp;</div>
                        <div>
                            <Dropdown
                                onClick={() => setFilterFlag(false)}
                                className="wrapper-dropdown black_action sm dark mx-1">
                                <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic">
                                    {" "}
                                    Export{" "}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        href="javascript:;"
                                        onClick={onClickHandler}>
                                        PNG
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="divider mx-2">&nbsp;</div>

                        <div className="position-relative">
                            {
                                <span className={filterCount ? "count" : ""}>
                                    {filterCount ? filterCount : null}
                                </span>
                            }
                            <div className="wrapper-dropdown black_action no-carat sm dark">
                                <a
                                    href="javascript:;"
                                    onClick={() => filterFunction(false)}>
                                    <img src={FilterImg} alt="Filter" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Filter
                filterFlag={filterFlag}
                filterFunction={(flag, clear) => filterFunction(flag, clear)}
                getFilterData={(data, type) => getFilterData(data, type)}
                contentSearch={contentSearch}
                filterDropdownOption={filterDropdownOption}
                filterApply={flag => filterApply(flag)}
                oldSelected={oldSelected}
                oldSelectedStage={oldSelectedStage}
                oldSelectedFolder={oldSelectedFolder}
                setAllOption={setAllOption}
                oldSelectedMultiOptions={oldSelectedMultiOptions}
            />
        </div>
    );
};
export default Header;