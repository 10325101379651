import React from "react";
import WordFile from "../../../../assets/images/word.png";
import RightArrow from "../../../../assets/images/right-arrow.png";
import userProfile from "../../../../assets/images/user-profile.jpeg";

const IconPopup = ({ showIconPopup ,iconNode}) => {

    const dateString = iconNode?.created_at;
    const date = new Date(dateString);
    
    // Format the date
    const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    const formattedTime = `${date.getHours()}:${date.getMinutes()}`;
    const formattedDateTime = `${formattedDate} | ${formattedTime}`;

    return (
        <React.Fragment>
            {showIconPopup ?
                <div  className="user-details vertical-details">
                    <div style={{color: "black", fontWeight: 700, opacity:1}}>
                        <div className="custom-box custom-box--small">
                            <div className="modal_head">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <div className="doc_img">
                                            <img src={WordFile} alt="img" />
                                            </div>
                                        </div>
                                        <div>
                                            <h3>{iconNode?.name ? iconNode?.name : ""}</h3>
                                            <p>V.14 (Current Version)</p>
                                        </div>
                                    </div>
                                    <div className="right_arrow">
                                        <img src={RightArrow} alt="img" />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="modal_body">
                                <div className="row vertical">
                                    <div className="col-12">
                                        <table className="table table-borderless">
                                            <tr>
                                                <td>
                                                    <h6 className="mb-0">Durum :</h6>
                                                </td>
                                                <td>
                                                    <button className="btn btn-warning">{iconNode?.stageState ? iconNode?.stageState?.name : "default"}</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h6 className="mb-0">Ekleyen Kullanici :</h6>
                                                </td>
                                                <td>
                                                    <button className="btn btn-default mb-1 text-left">
                                                        <span className="user-sm-img"><img src={userProfile} alt="img"/></span>
                                                        <strong> {iconNode?.stateHistories?.length ? iconNode?.stateHistories[0]?.userData ? iconNode?.stateHistories[0]?.userData?.firstname + " "+iconNode?.stateHistories[0]?.userData?.lastname + " " + iconNode?.stateHistories[0]?.userData?.role : "default" : "default"}</strong>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h6 className="mb-0">Ekleme Tarihi :</h6>
                                                </td>
                                                <td>
                                                    <button className="btn btn-default mb-1"><strong>{formattedDateTime}</strong></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h6 className="mb-0">Dokuman Tipi :</h6>
                                                </td>
                                                <td>
                                                    <button className="btn btn-default btn-default1 mb-1">Sozlesme</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h6 className="mb-0">Versiyon :</h6>
                                                </td>
                                                <td>
                                                    <button className="btn btn-default btn-default1 mb-1">{iconNode?.stage_type ? iconNode?.stage_type : ""} <strong>{iconNode?.type ? iconNode?.type : ""}</strong></button>
                                                </td>
                                            </tr>
                                        </table>
                                        <h6 className="mb-4 mt-2">Versiyon Aciklamasi : </h6>
                                        <p className="font-normal small"> {iconNode?.description ? iconNode?.description : ""}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </React.Fragment>

    );
};
export default IconPopup;

